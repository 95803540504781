.termsOfUseAcceptance {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  display: grid;
  place-items: center;
  backdrop-filter: blur(8px);
}

.termsOfUseAcceptance.accepted {
  pointer-events: none;
  display: none;
}

.body {
  border-radius: 0.5em;
  max-width: calc(100vw - 2em);
  padding: 2em;
  background-color: #fff;
  color: #002;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 2em;
  box-shadow: 0 1em 2em #000;
}

.body > svg {
  width: 120px;
}

.body > footer {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.body a {
  text-decoration: underline;
}

.body > footer > button.button {
  line-height: 3;
  padding: 0 2em;
  border: 1px solid currentColor;
  background-color: #fff;
  color: currentColor;
  transition: all 0.3s;
}

.body > footer > button.button:hover {
  border: 1px solid #fff;
  background-color: #002766;
  color: #fff;
}
